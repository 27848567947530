<template>
  <div>
    <div
      v-if="!(field.type === 'array' && field.name === '_attributes')"
      :id="'item-start-' + field.id"
      class="tree-node-start tree-node px-2 cursor-pointer"
      :class="{ fieldset: field.type === 'fieldset' }"
      @mouseenter="onFieldHover(field.full_name)"
      @mouseleave="showFieldData = false"
      @click="toClipboard(field)"
    >
      <div
        class="tree-node-content d-flex justify-content-between align-items-center"
      >
        <div class="mr-1">
          <i class="type-icon mr-1" :class="typeIcon" />
          <span class="mr-1">{{ field.label }}</span>
          <span class="text-caption">({{ field.type }})</span>
        </div>
        <div class="text-no-wrap">
          <button
            v-if="children.length && !flat"
            class="btn-toggle-children btn btn-circle btn-icon mr-1"
            :class="{ open: showChildren }"
            style="height: 100%; width: 100%"
            @click="toggleChildren"
          >
            <i
              class="fal fa-chevron-down"
              style="font-size: 12px; height: 14px"
            />
          </button>
          <i
            v-if="field.type !== 'fieldset'"
            class="circle icon-lg cursor-move"
          />
        </div>
      </div>
    </div>

    <!-- Field data menu -->
    <v-menu
      v-model="showFieldData"
      :position-x="fieldMenuPositionX"
      :position-y="fieldMenuPositionY"
    >
      <v-list class="p-0" style="max-width: 20vw">
        <v-list-item dense>
          <v-list-item-title>
            {{ $t("dataSets.fieldData") }}
          </v-list-item-title>
        </v-list-item>
        <template v-for="(data, i) in fieldData">
          <v-divider v-show="i !== 0" :key="`${i}-divider`" class="my-0" />
          <v-list-item :key="i" class="field-data" dense>
            <v-list-item-subtitle v-if="data === null">
              {{ $t("dataSets.nullText") }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>{{ data }}</v-list-item-subtitle>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <!-- Field data menu -->

    <div
      v-if="children.length && showChildren && !flat"
      class="tree-node-children ml-6"
      :class="'children-' + field.id"
    >
      <div>
        <Node
          v-for="(child, i) in children"
          :key="i"
          :field="child"
          :source-fields="sourceFields"
          :flat="flat"
          :slug="
            slug +
            '.' +
            field.name +
            (field.type === 'collection' ? '_entry' : '')
          "
          :collection-keys="collectionKeys"
          :selected-data-structure-data="selectedDataStructureData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getTypeIcon } from "@/components/Mappings/helpers";
import { copyToClipboard } from "@/components/Tools/helperFunctions";

export default {
  components: {
    Node: () => import("@/components/TextTemplates/Components/Node")
  },
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    slug: {
      type: String,
      default: () => {}
    },
    sourceFields: {
      type: Array,
      default: () => {}
    },
    flat: Boolean,
    collectionKeys: {
      type: Array,
      default: () => []
    },
    selectedDataStructureData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showChildren: true,
      showFieldData: false,
      fieldMenuPositionX: 0,
      fieldMenuPositionY: 0,
      fieldData: []
    };
  },
  computed: {
    children: function () {
      return this.sourceFields.filter(
        field => field.parent_id === this.field.id
      );
    },
    typeIcon: function () {
      return getTypeIcon(this.field.type, this.showChildren);
    }
  },
  mounted() {},
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
      this.$nextTick().then(() => this.$emit("update-connections"));
    },
    openModal() {
      this.$emit("open-modal", {
        id: this.field.id,
        collectionKeys: this.collectionKeys
      });
    },
    onFieldHover(fieldName) {
      const path = fieldName.split(" - ")[0].split(".");
      this.fieldData = [];

      for (let key in this.selectedDataStructureData) {
        let item = this.selectedDataStructureData[key];

        let data = item[path[0]];
        for (let i = 1; i < path.length; i++) {
          if (data === null) {
            continue;
          }

          if (path[i] === "*") {
            path[i] = 0;
          }

          data = data[path[i]];
        }

        this.fieldData.push(data);
      }

      this.setFieldDataMenuPosition();
      this.showFieldData = true;
    },
    setFieldDataMenuPosition() {
      const element = document.getElementById("item-start-" + this.field.id);

      if (!element) {
        return;
      }
      const offset = element.getBoundingClientRect();
      this.fieldMenuPositionX = offset.x + offset.width + 3;
      this.fieldMenuPositionY = offset.y;
    },
    toClipboard(field) {
      let copyText = "";
      let fieldName = this.slug + "." + field.name;

      if (field.type === "collection") {
        let nextKey = field.name + "_key";
        let nextEntry = field.name + "_entry";

        copyText =
          "{% for " +
          nextKey +
          ", " +
          nextEntry +
          " in " +
          fieldName +
          " %}\n    \n{% endfor %}";
      } else {
        copyText = "{{ " + fieldName + " }}";
      }
      copyToClipboard(copyText);
    }
  }
};
</script>
